<template>
  <i
    class="iconfont"
    :title="collectionTip"
    @click="collectionHandle"
    :class="[isCollection ? 'icon-shoucangfill active' : 'icon-shoucang']"
  ></i>
</template>
<script>
import { isFavorite, addFavorite, cancelFavorite } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      collectionTip: '已收藏',
      isCollection: false,
    }
  },
  mounted() {
    if (this.$store.state.isLogin) {
      this.isFavoriteHandle()
    }
  },
  methods: {
    // 是否处于收藏状态
    isFavoriteHandle() {
      if (this.cookieMethods.get('token')) {
        let params = {
          id: this.$route.params.id,
          userId: this.$store.state.userId,
          spuId: this.$route.params.id,
        }
        isFavorite(params).then((res) => {
          if (res.code == '200') {
            this.isCollection = res.data
          } else {
            return
          }
        })
      }
    },
    // 取消收藏
    collectionHandle() {
      if (this.cookieMethods.get('token')) {
        let params = {
          id: this.$route.params.id,
          userId: this.$store.state.userId,
          spuId: this.$route.params.id,
        }
        if (this.isCollection) {
          cancelFavorite(params).then((res) => {
            if (res.code != '200') {
              this.$message.error(this.$trans('取消失败', '取消失败'))
            } else {
              this.isCollection = false
              this.$message.success(
                this.$trans('已取消到收藏列表', '已取消到收藏列表')
              )
            }
          })
        } else {
          addFavorite(params).then((res) => {
            if (res.code == '200') {
              this.isCollection = true
              this.$message.success(
                this.$trans('已添加到收藏列表', '已添加到收藏列表')
              )
            } else {
              this.$message.error(this.$trans('添加失败', '添加失败'))
            }
          })
        }
      } else {
        this.$router.history.push({
          path: '/login',
          query: {
            path: this.$route.path,
          },
        })
      }
    },
  },
  watch: {
    isCollection(value) {
      if (value) {
        this.collectionTip = '取消收藏'
      } else {
        this.collectionTip = '收藏'
      }
    },
  },
}
</script>
<style scoped>
i.iconfont {
  font-size: 18px;
  cursor: pointer;
}
i.active.iconfont {
  color: #2a9dcc;
}
</style>
