<template>
  <div>
    <div class="shopify-section-box" @scroll="onScroll">
      <div
        v-if="JSON.stringify(goodsDetail) === '{}'"
        class="empty-box"
        @click="getGoodsDetail"
      >
        <el-empty
          image="https://img01.yzcdn.cn/vant/custom-empty-image.png"
        ></el-empty>
      </div>
      <el-row v-else>
        <el-col :span="8">
          <div class="shopify-section-left">
            <div class="artist-info flex-end">
              <div class="artist-title font-medium">
                <!-- {{ goodsDetail.title }} -->
                {{ $trans(`goods-${goodsDetail.id}.title`, goodsDetail.title) }}
              </div>
              <div class="artist-author">
                「{{
                  $trans(
                    `artist-${goodsDetail.artistId}.name`,
                    goodsDetail.artistName
                  )
                }}」
              </div>
            </div>
            <div class="object__price">
              <div class="object__int" style="font-style: normal">￥</div>
              <div class="object__int">
                {{ goodsDetail.price | product_int }}
              </div>
              <div class="object__float">
                {{ goodsDetail.price | product_float }}
              </div>
              <CollectionItem />
            </div>
            <div class="fixed-wrap">
              <div
                class="btn-fill car-btn-fill"
                :data-label="$trans('加入购物车', '加入购物车')"
                @click="openSpecHandle"
              >
                <div
                  class="flex-column-cen car-absolute"
                  :data-label="$trans('加入购物车', '加入购物车')"
                >
                  <div
                    class="car-absolute-normal"
                    :data-label="$trans('加入购物车', '加入购物车')"
                  >
                    <span
                      class=""
                      :data-label="$trans('加入购物车', '加入购物车')"
                      >{{ $trans('加入购物车', '加入购物车') }}</span
                    >
                  </div>
                  <div
                    class="car-absolute-active"
                    :data-label="$trans('加入购物车', '加入购物车')"
                  >
                    <i
                      class="iconfont icon-shiwu-gouwuche"
                      :data-label="$trans('加入购物车', '加入购物车')"
                    ></i>
                  </div>
                </div>
              </div>
              <div
                class="btn-fill buy-btn-fill"
                :data-label="$trans('立即购买', '立即购买')"
                @click="openSpecHandle"
              >
                {{ $trans('立即购买', '立即购买') }}
              </div>
            </div>
            <div
              v-html="
                $trans(`goods-${goodsDetail.id}.content`, goodsDetail.content)
              "
              class="paragraph font-medium"
            ></div>
            <!-- <p
              class="paragraph font-medium"
              v-for="(item, index) in content"
              :key="index"
            >
              {{ item }}
            </p> -->
          </div>
        </el-col>
        <el-col :span="16">
          <div class="shopify-section-right">
            <div class="iframe-images-wrap">
              <div v-for="(item, index) in goodsDetail.imageList" :key="index">
                <div v-if="index == 0">
                  <div
                    class="iframe-wrap flex-cen-cen"
                    ref="leftBox"
                    v-if="item"
                  >
                    <div
                      :class="[
                        goodsDetail.styleType == 0 ? '' : 'border-iframe-outer',
                      ]"
                    >
                      <div
                        :class="[
                          goodsDetail.styleType == 0
                            ? ''
                            : 'border-iframe-inner',
                        ]"
                      >
                        <img :src="item" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div
                    class="iframe-wrap flex-cen-cen"
                    ref="leftBox"
                    style="padding: 0"
                    v-if="item"
                  >
                    <img :src="item" alt="" />
                  </div>
                </div>
              </div>
              <div class="iframe-wrap" style="padding: 0" v-if="detailImage">
                <img
                  :src="
                    $trans(`goods-${goodsDetail.id}.detailImage`, detailImage)
                  "
                  alt=""
                />
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="shopify-section-fixed flex-column-start">
        <div v-for="(item, index) in goodsDetail.imageList" :key="index">
          <div v-if="index == 0">
            <div
              class="iframe-wrap flex-cen-cen"
              :class="index == activeIndex ? 'active' : ''"
              @click="onScrollHandle(index)"
              v-if="item"
            >
              <div class="border-iframe-outer">
                <div class="border-iframe-inner">
                  <img :src="item" alt="" />
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <div
              class="iframe-wrap flex-cen-cen"
              :class="index == activeIndex ? 'active' : ''"
              @click="onScrollHandle(index)"
            >
              <img
                :src="item"
                alt=""
                style="max-width: 100%; max-height: 100%"
              />
            </div>
          </div>
        </div>
      </div>
      <AboutAuthor
        :data="
          $trans(
            `artist-${goodsDetail.artistId}.goodsBrief`,
            goodsDetail.artistBrief
          )
        "
        :bgImage="bgImage"
        :artistId="goodsDetail.artistId"
      />
      <SwiperRecommend />
    </div>
    <Footer />
    <div class="shop-detail-mask" v-show="show">
      <div class="mask-content">
        <div class="mask-content-header">
          <div class="mask-price object__price">
            <div class="object__int" style="font-style: normal">￥</div>
            <div class="object__int">
              {{ selectOptions.price ? selectOptions.price : 0 | product_int }}
            </div>
            <div class="object__float">
              {{
                selectOptions.price ? selectOptions.price : 0 | product_float
              }}
            </div>
          </div>
          <div class="mask-store">
            {{
              selectOption.length > 0 && selectOption[0].count >= number
                ? $trans('有货', '有货')
                : $trans('缺货', '缺货')
            }}
          </div>
          <div class="mask-select-tip">
            {{ $trans('请选择', '请选择') }}
            {{
              goodsDetail.specVoList &&
              goodsDetail.specVoList.reduce(
                (a, b) =>
                  a + '  ' + $trans(`attr-${b.attrId}.attrName`, b.title),
                ''
              )
            }}
          </div>
          <div class="mask-img__wrap flex-cen-cen" v-if="goodsDetail.imageList">
            <img :src="specifImage" alt="" />
          </div>
        </div>
        <div class="mask-category__wrap">
          <div
            class="mask-category"
            v-for="(item, index) in goodsDetail.specVoList"
            :key="index"
          >
            <div class="mask-category-title">
              {{ $trans(`attr-${item.attrId}.attrName`, item.title) }}({{
                item.valueList.length
              }})
            </div>
            <el-radio-group v-model="specVoListSelect[index]" size="small">
              <el-radio
                :label="ite"
                border
                v-for="(ite, inde) in item.valueList"
                :key="inde"
                >{{ ite }}</el-radio
              >
            </el-radio-group>
          </div>
          <div class="mask-category">
            <div class="mask-category-title">
              {{ $trans('购买数量', '购买数量') }}
            </div>
            <el-input-number
              size="medium"
              :min="1"
              v-model="number"
            ></el-input-number>
          </div>
        </div>
        <div class="confirm-button btn-fill" @click="summitOrderHandle">
          {{ selectOption.length > 0 ? label : '缺货' }}
        </div>
        <div class="mask-delete" @click="show = false">×</div>
      </div>
    </div>
  </div>
</template>
<script>
import { loadLocaleMessages } from '@/i18n/i18n'
import CollectionItem from '@/components/shop/CollectionItem.vue'
import Footer from '@/components/layout/Footer.vue'
import SwiperRecommend from '@/components/swiper/SwiperRecommend.vue'
import AboutAuthor from '@/components/shop/AboutAuthor.vue'
import { getGoodsDetail, addShopCar, confirmOrder } from '@/util/js/api.js'
export default {
  name: '',
  data() {
    return {
      bgImage: '',
      activeArr: [],
      activeIndex: 0,
      number: 1, // 选择商品数量
      show: false, // 是否打开弹窗
      label: this.$trans('加入购物车', '加入购物车'), // 弹窗按钮文本
      goodsDetail: {}, // 商品详情信息
      getGoodsDetails: false, // 是否在请求商品详情信息
      specVoListSelect: [], // 规格选择值
      content: [], // 内容
      detailImage: '',
    }
  },
  mounted() {
    this.init()
    window.onresize = this.init
    if (this.$route.params.id) {
      this.getGoodsDetail()
    } else {
      this.$router.history.push('/')
    }
    if (document.querySelector('.main__layout')) {
      document
        .querySelector('.main__layout')
        .scrollTo({ top: 0, behavior: 'smooth' })
    }
  },
  metaInfo() {
    return {
      title:
        this.goodsDetail && this.goodsDetail.title
          ? `${this.goodsDetail.title}-${this.goodsDetail.artistName}-上殊房`
          : '上殊房',
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.content ? this.content[0] : '',
        },
      ],
    }
  },
  methods: {
    // 初始化
    init() {
      setTimeout(() => {
        let arr = []
        if (this.$refs['leftBox']) {
          this.$refs['leftBox'].forEach((item) => {
            arr.push(item.offsetTop)
          })
        }
        document
          .querySelector('.main__layout')
          .addEventListener('scroll', this.onScroll)
        this.activeArr = arr
      }, 300)
    },
    // 滚动操作
    onScroll() {
      let height = document.querySelector('.main__layout').scrollTop
      for (let i = this.activeArr.length - 1; i >= 0; i--) {
        if (height >= this.activeArr[i]) {
          this.activeIndex = i
          break
        }
      }
    },
    // 滚动操作
    onScrollHandle(index) {
      document
        .querySelector('.main__layout')
        .scrollTo({ top: this.activeArr[index], behavior: 'smooth' })
      setTimeout(() => {
        this.activeIndex = index
      }, 300)
    },
    // 打开弹窗页面
    openSpecHandle(e) {
      e.stopPropagation()
      if (!this.cookieMethods.get('token')) {
        this.logoutHandleMethods.call(
          this,
          '/login',
          this.$route.path,
          '先登录再操作'
        )
        return
      }
      this.show = !this.show
      this.label = e.target.dataset.label
    },
    // 提交
    summitOrderHandle() {
      const obj = this.getUserInfo.call(this, 'user')
      if (obj && obj['status'] != 3) {
        this.$message.warning(this.$trans('请先完成实名', '请先完成实名'))
        this.$router.history.push('/setting/authentication')
        return
      }
      if (this.selectOptions.count < this.number) {
        this.$message.warning(
          this.$trans(
            '当前商品库存不足,请选择其他商品',
            '当前商品库存不足,请选择其他商品'
          )
        )
        return
      }
      // return
      let a = this.specVoListSelect.every((item) => item)
      if (!a) {
        this.$message.warning(this.$trans('请选择规格属性', '请选择规格属性'))
        return
      }
      // debugger
      let params = {
        userId: this.$store.state.userId,
        count: this.number,
        spuId: this.selectOptions.skuId,
        addType: 0,
        skuId: this.selectOptions.skuId,
      }
      if (this.selectOption.length > 0) {
        if (this.label == this.$trans('加入购物车', '加入购物车')) {
          addShopCar(params)
            .then((res) => {
              if (res.code == '200') {
                this.number = 1
                this.$message.success(this.$trans('添加成功', '添加成功'))
                this.show = !this.show
              }
            })
            .catch(() => {
              this.$message.error(this.$trans('添加失败', '添加失败'))
            })
          return
        } else {
          let param = {
            goodsVoList: [
              {
                count: this.number,
                skuId: this.selectOptions.skuId,
                price: this.selectOptions.price,
              },
            ],
            userId: this.$store.state.userId,
          }
          confirmOrder(param)
            .then((res) => {
              if (res.code == '200') {
                this.$router.history.push({
                  path: '/order/new',
                  query: {
                    goodsDetailVoList: JSON.stringify([
                      {
                        ...params,
                        image: this.goodsDetail.imageList[0],
                        title: this.goodsDetail.title,
                        artistName: this.goodsDetail.artistName,
                        artistId: this.goodsDetail.artistId,
                        price: this.selectOptions.price,
                        count: this.number,
                        spu: this.goodsDetail.id,
                      },
                    ]),
                    orderToken: JSON.stringify(res.data.orderToken),
                    address: JSON.stringify(res.data.shangsfAddress),
                    isCartGoods: 0,
                    shangsfSkuTemplateItemList: res.data.goodsVoList
                      ? JSON.stringify(res.data.goodsVoList)
                      : JSON.stringify([]),
                    couponVoList: res.data.couponVoList
                      ? JSON.stringify(res.data.couponVoList)
                      : JSON.stringify([]),
                  },
                })
              }
            })
            .catch(() => {
              this.$message.error(
                this.$trans(
                  '确认订单信息错误,请稍后再试',
                  '确认订单信息错误,请稍后再试'
                )
              )
            })
          return
        }
      } else {
        this.$message.warning(this.$trans('该商品暂时无货', '该商品暂时无货'))
      }
    },
    // 获取商品详情
    getGoodsDetail() {
      if (this.getGoodsDetails) {
        this.$message.warning(
          this.$trans('请不要频繁发送请求', '请不要频繁发送请求')
        )
        return
      }
      this.getGoodsDetails = true
      getGoodsDetail({ id: this.$route.params.id })
        .then((res) => {
          this.getGoodsDetails = false
          this.goodsDetail = res.data
            ? JSON.parse(JSON.stringify(res.data))
            : []
          let arr = res.data.images ? res.data.images.split(',') : []
          this.goodsDetail.imageList = arr
          this.detailImage = res.data.detailImage
          for (let i = 0; i < res.data.specVoList.length; i++) {
            this.specVoListSelect[i] = ''
            const attr = res.data.specVoList[i]
            if (attr.transferList) {
              loadLocaleMessages(attr.transferList, `attr-${attr.attrId}`)
            }
          }
          this.content = res.data.content
            ? JSON.parse(JSON.stringify(res.data.content.split('<sign>')))
            : []
          this.bgImage = res.data.artistImage.split(',')[1]

          if (res.data.transferList) {
            loadLocaleMessages(res.data.transferList, `goods-${res.data.id}`)
          }
          if (res.data.artistTransferList) {
            loadLocaleMessages(
              res.data.artistTransferList,
              `artist-${res.data.artistId}`
            )
          }
        })
        .catch(() => {
          this.getGoodsDetails = false
          this.$message.error(
            this.$trans('网络错误,没有获取到数据', '网络错误,没有获取到数据')
          )
        })
    },
    convert(input) {
      const result = {}
      for (const lang in input) {
        const langArray = input[lang]
        const langObject = {}
        langArray.forEach((item) => {
          langObject[item.langKey] = item.langValue
        })
        result[lang] = langObject
      }
      return result
    },
  },
  components: {
    Footer,
    SwiperRecommend,
    AboutAuthor,
    CollectionItem,
  },
  computed: {
    selectOptions() {
      return this.selectOption && this.selectOption.length > 0
        ? this.selectOption[0]
        : []
    },
    selectOption() {
      const res =
        this.goodsDetail.goodsDetailVoList &&
        this.goodsDetail.goodsDetailVoList.length > 0
          ? [
              ...this.goodsDetail.goodsDetailVoList.filter((item) =>
                this.specVoListSelect.every(
                  (ite) => item.skuName.indexOf(ite) > -1
                )
              ),
            ]
          : []
      // console.log('res:', res)
      return res
    },
    specifImage() {
      let a = this.specVoListSelect.every((item) => {
        return item
      })
      return a ? this.selectOptions.image : this.goodsDetail.image
    },
  },
}
</script>
<style scoped>
.shopify-section-box {
  position: relative;
}
.shopify-section-left {
  border-right: 1px solid rgba(17, 17, 17, 0.1);
  padding: 80px 10% 0;
}
.artist-info {
  justify-content: center;
  margin-bottom: 10px;
}
.artist-title {
  font-size: 18px;
}
.artist-author {
  font-size: 12px;
  color: #595959;
}
.object__price {
  margin-bottom: 30px;
}
.paragraph {
  line-height: 2;
  font-size: 14px;
  margin-bottom: 40px;
}
.share-wrap {
  font-size: 14px;
  margin-top: 100px;
  margin-bottom: 100px;
  cursor: pointer;
}
.share-wrap .icon-fenxiang {
  font-size: 26px;
  margin-right: 10px;
}
.shopify-section-right {
  margin-right: 200px;
  flex: 1;
}
.shopify-section-right .iframe-images-wrap {
  display: grid;
  row-gap: 30px;
  padding-top: 30px;
  padding-bottom: 30px;
}
.shopify-section-right .iframe-wrap {
  max-width: 600px;
  width: 100%;
  padding: 10%;
  background: rgb(226, 226, 226);
}
.shopify-section-right .border-iframe-outer {
  border: 12px solid #111111;
}
.shopify-section-right .border-iframe-inner {
  border: 12px solid #ffffff;
}
.shopify-section-right .iframe-wrap img {
  max-width: 400%;
  max-height: 400%;
  width: 100%;
  height: auto;
}
.shopify-section-fixed {
  position: fixed;
  top: 50%;
  transform: translateY(-50%);
  right: 100px;
  z-index: 999;
}
.shopify-section-fixed .iframe-wrap {
  width: 60px;
  height: 60px;
  background: rgb(248, 248, 248);
  margin-bottom: 20px;
  border: 1px solid rgb(248, 248, 248);
  cursor: pointer;
}
.shopify-section-fixed .iframe-wrap.active {
  border: 1px solid #c0c0c0;
}
.shopify-section-fixed .border-iframe-outer {
  border: 2px solid #000000;
}
.shopify-section-fixed .border-iframe-inner {
  border: 2px solid #ffffff;
}
.shopify-section-fixed img {
  max-width: 40px;
  max-height: 40px;
}
.car-btn-fill {
  background: rgb(144, 144, 144);
  margin-bottom: 30px;
  overflow: hidden;
  position: relative;
}
.car-absolute {
  height: 200%;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  transition: all 0.2s;
}
.car-absolute-normal {
  width: 100%;
  background: #111111;
}
.car-btn-fill:hover .car-absolute {
  top: -100%;
}
.buy-btn-fill {
  background: rgb(87, 87, 87);
  margin-bottom: 70px;
}
.buy-btn-fill:hover {
  background: rgb(144, 144, 144);
}
@media screen and (max-width: 1100px) {
  .shopify-section-right {
    margin-right: unset;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 0) and (min-width: 1099px) {
  .shopify-section-right .iframe-wrap img {
    max-height: unset;
  }
}
</style>
<style>
.el-radio.is-bordered {
  margin-left: 10px;
  margin-bottom: 5px;
}
</style>
<style scoped>
.shop-detail-mask {
  position: fixed;
  width: 75%;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.2);
  z-index: 1000;
}
.mask-content {
  background: #ffffff;
  position: relative;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 700px;
  height: 700px;
  padding-top: 42px;
  padding-bottom: 48px;
}
.mask-content-header {
  position: relative;
}
.mask-delete {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}
.mask-price {
  color: #2a9dcc;
  font-size: 24px;
}
.mask-store {
  text-align: center;
  color: #595959;
  margin-bottom: 17px;
  margin-top: 17px;
}
.mask-select-tip {
  text-align: center;
  padding-bottom: 24px;
}
.mask-category {
  border-top: 1px solid rgba(17, 17, 17, 0.1);
  padding: 15px 30px 40px;
}
.mask-category-title {
  margin-bottom: 15px;
}
.mask-img__wrap {
  position: absolute;
  left: 20px;
  width: 100px;
  height: 100px;
  background: #dfdfdf;
  top: 0px;
}
.mask-img__wrap img {
  max-width: 100px;
  max-height: 100px;
  width: auto;
  height: auto;
}
</style>
<style>
.el-radio__input {
  display: none;
}
.el-radio__label {
  padding: 0;
}
.el-radio--small.is-bordered {
  padding: 8px 8px 0 8px;
}
.el-input-number--medium {
  width: 110px;
}
.confirm-button {
  margin: 50px auto;
  width: 350px;
}
.mask-category__wrap {
  height: calc(700px - 100px - 123px - 90px);
  overflow-y: scroll;
}
</style>
