<template>
  <div class="shopify-guest-section">
    <el-divider
      ><span class="shopify-guest-title font-bold">
        {{ $trans('猜你喜欢', '猜你喜欢') }}</span
      ></el-divider
    >
    <div class="swiper-container swiper-container-e">
      <div class="swiper-wrapper">
        <div class="swiper-slide" v-for="(item, index) in data" :key="index">
          <ShopItem :data="item" />
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination-e"></div>
    </div>
  </div>
</template>
<script>
import ShopItem from '@/components/shop/ShopItem.vue'
import { spuBySpuld } from '@/util/js/api.js'
import Swiper from 'swiper'
export default {
  name: '',
  components: {
    ShopItem,
  },
  data() {
    return {
      data: [], // 数据
    }
  },
  mounted() {
    this.getSpuBySpuld()
  },
  methods: {
    // 获取推荐商品
    getSpuBySpuld() {
      let params = {
        spuId: this.$route.params.id,
        userId: this.$store.state.userId ? this.$store.state.userId : '',
      }
      spuBySpuld(params).then((res) => {
        if (res.code == '200') {
          let arr = JSON.parse(JSON.stringify(res.data.rows))
          arr = arr.map((item) => {
            let params = {
              ...item,
            }
            params.artistName = params.artist.name
            return params
          })
          this.data = JSON.parse(JSON.stringify(arr))
          setTimeout(() => {
            new Swiper('.swiper-container-e', {
              slidesPerView: 'auto',
              spaceBetween: 30,
              pagination: {
                el: '.swiper-pagination-e',
                clickable: true,
              },
            })
          }, 300)
        }
      })
    },
  },
}
</script>
<style>
.shopify-guest-section .shopify-guest-title {
  font-size: 20px;
}
.shopify-guest-section .swiper-container-e {
  padding-bottom: 100px;
  margin-bottom: 100px;
}
.shopify-guest-section .swiper-slide {
  width: 300px;
}
.shopify-guest-section .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
}
.shopify-guest-section .swiper-pagination-bullet-active {
  background: rgb(0, 0, 0);
}
.shopify-guest-section .el-divider--horizontal {
  margin: 100px 80px 60px;
}
.shopify-guest-section .el-divider--horizontal {
  width: unset;
}
</style>
