<template>
  <div class="shopify-section__picture">
    <a :href="artistId ? '/artist/detail/' + artistId : ''">
      <img :src="bgImage" alt="" />
      <div class="mask">
        <div class="shopify-mask-title">
          {{ $trans('上殊房背后的艺术家', '上殊房背后的艺术家') }}
        </div>
        <div class="shopify-mask-content font-light ellips-4">{{ data }}</div>
      </div>
    </a>
  </div>
</template>
<script>
export default {
  name: '',
  props: {
    bgImage: {
      typeof: String,
    },
    data: {
      typeof: String,
    },
    artistId: {
      typeof: String,
    },
  },
}
</script>
<style scoped>
.shopify-section__picture {
  position: relative;
  margin-top: 50px;
  margin-bottom: 50px;
  min-height: 300px;
  overflow: hidden;
}
.shopify-section__picture .mask {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 300px;
  background: rgba(17, 17, 17, 0.5);
  padding: 80px 200px;
  color: #ffffff;
}
.shopify-section__picture .shopify-mask-title {
  font-size: 24px;
  line-height: 1;
  margin-bottom: 40px;
}
.shopify-section__picture .shopify-mask-content {
  font-size: 16px;
  line-height: 2;
}
</style>
